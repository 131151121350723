import $ from 'jquery'

const movingButton = () => {
    $(function(){
        $('button').on({
            mouseover:function(){
                $('button').css({
                    //left:(Math.random()*200)+"px",
                    bottom:(Math.random()*80)+'%',
                });
            }
        });
    });
}
  
export default movingButton;