import React from 'react';
import './App.css';
import movingButton from './component/movingButton'


class App extends React.Component {
  
  componentDidMount() {
    movingButton()
  }

  render() {
  
    return (
      <div className="App">
      <button className="zocial facebook">Chat with Helpdesk &nbsp; &nbsp;</button>
    </div>
    )
  }

}

export default App;
